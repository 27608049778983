(function($) {

    var siteHeader = $( '.site-header--static' ),
        siteHeaderFixed = $( '.site-header--fixed' );

    if ( ! siteHeaderFixed ) {
        siteHeader.clone(true).appendTo('body').addClass('.site-header--fixed');
    }

    // Remove the canvas attribute from the regular header
    siteHeader.removeAttr( 'canvas' );

    /**
     * When not at the very top of the page, a class is added to the header
     * element. The CSS makes it fixed at the top of the page so the user
     * always has access to the header.
     */
    function shrinkHeader( element ) {
        var distanceY = window.pageYOffset || document.documentElement.scrollTop || $('body').scrollTop(),
            shrinkOn = siteHeaderFixed.data( 'shrink-on' );

        if (distanceY > shrinkOn) {
            element.addClass('visible');
            element.css('opacity', 1);
        } else {
            element.removeClass('visible');
            element.css('opacity', 0);
        }
    }

    shrinkHeader( siteHeaderFixed );

    window.addEventListener( 'scroll', function(e){
        shrinkHeader( siteHeaderFixed );
    } );

})(jQuery);